import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f639a67e = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _7386d179 = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _cb7b88c8 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _2614a9fc = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _0c52e7a2 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _71444794 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _33be3552 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _7f4a21ad = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _8ecf3714 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _11204017 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _72bfbfab = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _2d1ec776 = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _bca6b8bc = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _b5f2e6cc = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _7cf4da05 = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _6a15b3ac = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _739aa8e2 = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _ce12998e = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _cb8bface = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _2a7d88e8 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _249e11d0 = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _43d7b684 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _4c89b569 = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _ee607022 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _ab2d3ed4 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _51b2caea = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _5a2429c8 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _00903bc0 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _ce5e4f52 = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _26d243d6 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _62ea209a = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _6a90eaeb = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _ed664c26 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _85d27052 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _50859932 = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _72db008c = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _8bd24c9c = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _a9d9e004 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _de7f2804 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _5db8730a = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _6312bc75 = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _92dceb50 = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _68f9ee5b = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _578e2eb2 = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _3e195e51 = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _6f71ac9a = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _47341b21 = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _585cf568 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _c60f29a0 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _5451dca2 = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _7b03a221 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _509af158 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _5c08af8e = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _775fa78a = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _131b4815 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _76675270 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _6b70541b = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _6d4baaff = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _126a7fd2 = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _49317a4a = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _4ebaa7da = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _1aac401a = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _01276b69 = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _29227504 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _0311f704 = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _6d4b2aec = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _967de744 = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _43ffb0d3 = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _083092bb = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _34134dcf = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _7fdf1f7d = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _6e8dd21b = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _1292ebed = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _f639a67e,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _7386d179,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _cb7b88c8,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _2614a9fc,
    name: "calendar"
  }, {
    path: "/customer",
    component: _0c52e7a2,
    name: "customer"
  }, {
    path: "/details",
    component: _71444794,
    name: "details"
  }, {
    path: "/goals",
    component: _33be3552,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _7f4a21ad,
    name: "maisoku"
  }, {
    path: "/media",
    component: _8ecf3714,
    name: "media"
  }, {
    path: "/privacy",
    component: _11204017,
    name: "privacy"
  }, {
    path: "/project",
    component: _72bfbfab,
    name: "project"
  }, {
    path: "/response",
    component: _2d1ec776,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _bca6b8bc,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _b5f2e6cc,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _7cf4da05,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _6a15b3ac,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _739aa8e2,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _ce12998e,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _cb8bface,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _2a7d88e8,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _249e11d0,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _43d7b684,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _4c89b569,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _ee607022,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _ab2d3ed4,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _51b2caea,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _5a2429c8,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _00903bc0,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _ce5e4f52,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _26d243d6,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _62ea209a,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _6a90eaeb,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _ed664c26,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _85d27052,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _50859932,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _72db008c,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _8bd24c9c,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _a9d9e004,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _de7f2804,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _5db8730a,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _6312bc75,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _92dceb50,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _68f9ee5b,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _578e2eb2,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _3e195e51,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/registration",
    component: _6f71ac9a,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _47341b21,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _585cf568,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _c60f29a0,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _5451dca2,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _7b03a221,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _509af158,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _5c08af8e,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _775fa78a,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _131b4815,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _76675270,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _6b70541b,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _6d4baaff,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _126a7fd2,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _49317a4a,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _4ebaa7da,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _1aac401a,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _01276b69,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _29227504,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _0311f704,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _6d4b2aec,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _967de744,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _43ffb0d3,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _083092bb,
    name: "property-property",
    children: [{
      path: "contact",
      component: _34134dcf,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _7fdf1f7d,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _6e8dd21b,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _1292ebed,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
